import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';
import routes from './routes';

// 原本是用BASE_URL，但設定不進去，要改用VUE_APP_BASE_URL
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  // base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

function updateLanguage(setting, lang) {
  if (lang) {
    let language = lang == 'en' ? lang : 'zh-TW';
    localStorage.setItem('lang', language);
    setting.locale = language;
  } else {
    setting.locale = localStorage.getItem('lang');
  }
}

router.beforeEach((to, from, next) => {
  console.log(to, from);

  if (to.path === '/error') {
    return next();
  }

  store
    .dispatch('initPortal')
    .then(() => {
      updateLanguage(i18n, to.query.lang);

      let appId = store.state.portalInfo.sso?.ddauthAppId || store.state.portalInfo.sso?.appId;
      let isDDAtuh = store.state.portalInfo.sso?.ddauthAppId ? true : false;
      const sso = new window.qcsso();
      // ddauth 登入頁
      if (to.path === '/login') {
        if (!appId) return console.error('no appId');
        if (isDDAtuh) next();
        next('/home');
        // sso.init({ appId }).then(() => {
        //   if (!sso.isLoggedIn()) {
        //     sso.ddauth({
        //       redirectUrl: `${process.env.VUE_APP_HOST}${
        //         process.env.BASE_URL
        //       }/home`,
        //     });
        //   }
        // });
      } else if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!appId) return console.error('no appId');
        sso.init({ appId }).then(() => {
          if (!sso.isLoggedIn()) {
            if (isDDAtuh) {
              return next('/login');
              // if (to.path === '/login') {
              //   sso.ddauth({
              //     redirectUrl: `${process.env.VUE_APP_HOST}${
              //       process.env.BASE_URL
              //     }${to.path.slice(1)}`,
              //   });
              // } else {
              //   console.log(
              //     'redirect to login page'
              //   );
              //   next("/login");
              // }
            }else {
              sso.login({
                redirectUrl: `${process.env.VUE_APP_HOST}${
                  process.env.BASE_URL
                }${to.path.slice(1)}`,
              });
            }
            // if (isDDAtuh) {
            //   console.log('login with ddauth....');
            //   sso.ddauth({
            //     redirectUrl: `${process.env.VUE_APP_HOST}${
            //       process.env.BASE_URL
            //     }${to.path.slice(1)}`,
            //   });
            // } else {
            //   console.log('login with qc ....');
            //   sso.login({
            //     redirectUrl: `${process.env.VUE_APP_HOST}${
            //       process.env.BASE_URL
            //     }${to.path.slice(1)}`,
            //   });
            // }
          } else {
            sso
              .getProfile()
              .then(function (user) {
                store.commit('updateUser', user);

                if (
                  store.state.billingAccounts.length === 0 ||
                  to.path === '/home'
                ) {
                  store
                    .dispatch('fetchBillingAccounts')
                    .then(() => {
                      return to.path == '/' ? next('/home') : next();
                    })
                    .catch(() => {
                      next('/error');
                    });
                } else {
                  return to.path == '/' ? next('/home') : next();
                }
              })
              .catch(function (e) {
                console.error(e);
                next('/error');
              });
          }
        });
      } else if (to.matched.some(record => record.meta.pwaCheck)) {
        // 不用登入但是如果登入就跳轉
        if (!appId) return console.error('no appId');

        sso.init({ appId }).then(() => {
          if (!sso.isLoggedIn()) {
            next();
          } else {
            next('/home');
          }
        });
      } else {
        next(); // make sure to always call next()!
      }
    })
    .catch(() => {
      next('/error');
    });
});

export default router;
